<template>
    <div class="we-make">
        <h2 v-html="$t('weOffer.heading')" class="we-make__heading"></h2>

        <ul class="list">
            <li
                v-for="item in items"
                :key="item.title"
                class="list__item"
            >
                <svg-icon :icon="`wemake/${item.icon}`" />

                <h3>{{ item.title }}</h3>

                <ul>
                    <li
                        v-for="listItem in item.list"
                        :key="listItem"
                    >
                        {{ listItem }}
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon'

export default {
    name: 'WeMake',
    components: {
        SvgIcon,
    },
    computed: {
        items() {
            return [
                {
                    icon: 'graphic-design',
                    title: this.$t('weOffer.graphicDesign.heading'),
                    list: this.$t('weOffer.graphicDesign.list'),
                },
                {
                    icon: 'web-design',
                    title: this.$t('weOffer.webDesign.heading'),
                    list: this.$t('weOffer.webDesign.list'),
                },
                {
                    icon: 'web-development',
                    title: this.$t('weOffer.webDevelopment.heading'),
                    list: this.$t('weOffer.webDevelopment.list'),
                },
            ]
        },
    },
}
</script>

<style lang="scss">
.we-make {
    min-height: 140px;
    margin-top: 48px;
    font-size: 14px;
    font-weight: 300;

    @media (min-width: 768px) {
        margin-top: 96px;
    }

    h2 {
        margin-bottom: 72px !important;
        font-size: 18px !important;

        @media (min-width: 768px) {
            margin-bottom: 72px !important;
            font-size: 22px !important;
        }
    }

    h3 {
        margin: 18px 0 6px 0;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
    }

    .list {
        @media (min-width: 768px) {
            display: flex;
        }

        &__item {
            position: relative;
            margin-bottom: 60px;
            padding: 16px;
            border-radius: 10px;
            background-color: #e4e4e4;
            box-shadow: #0000004a 0 1px 6px;

            &:last-child {
                margin-bottom: 0;
            }

            @media (min-width: 768px) {
                height: 180px;
                margin-right: 12px;

                &:last-child {
                    margin-right: 0;
                }
            }

            @media (min-width: 1024px) {
                width: 100%;

                margin-right: 24px;

                &:last-child {
                    margin-right: 0;
                }
            }

            ul {
                padding-left: 12px;
            }

            li {
                margin-bottom: 8px;
                color: #434343;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                list-style-type: none;

                @media (min-width: 1300px) {
                    font-size: 16px;
                }
            }
        }
    }

    .svg-container {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -92%);
        font-size: 58px;
    }
}
</style>
