<template>
    <div class="why-us">
        <div class="why-us__heading">
            <h2>
                {{
                    $t('whyUs.heading')
                }}

            </h2>
        </div>

        <p
            v-for="item in $t('whyUs.items')"
            :key="item"
            v-html="item"
        >
        </p>

        <we-make />
    </div>
</template>

<script>
import WeMake from '@/components/WeMake'

export default {
    name: 'WhyUs',
    components: {
        WeMake,
    },
    mounted() {
        document.getElementById('why-us-go-to-features').addEventListener('click', () => {
            this.$router.push('/features').catch(error => console.log('go to features error', error))
        })
    },
}
</script>

<style lang="scss">
.why-us {
    position: relative;
    margin: 0 -18px;
    padding: 48px 18px;
    background-color: white;
    box-shadow: #00000061 0 19px 38px;

    @media (min-width: 768px) {
        padding: 48px 24px;
    }

    @media (min-width: 1024px) {
        padding: 48px;
    }

    @media (min-width: 1300px) {
        padding: 66px;
    }

    h2 {
        text-align: center;
    }

    p {
        color: #434343;
        text-align: center;

        @media (min-width: 768px) {
            font-size: 18px;
            text-align: justify;
        }
    }

    &__heading {
        position: relative;
        width: fit-content;
        margin: 0 auto 36px;
    }
}
</style>
