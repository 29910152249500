<template>
    <div class="home">
        <div
            class="intro"
            :style="
                device.includes('mobile')
                    ? `background-image: url(/img/hero/home-mobile.png)`
                    : `background-image: url(/img/hero/home-desktop.png)`
            "
        >
            <h1>
                {{ $t('top.headingHome.first') }}<br>
                <b>{{ $t('top.headingHome.second') }}</b>
            </h1>

            <div class="intro__text">
                <h2 v-html="$t('top.heading')"></h2>

                <h2 v-html="$t('top.text')"></h2>
            </div>

            <!-- <img src="/img/hero/home-mobile.png" alt=""> -->

            <div class="intro__content">
                <div class="button-group">
                    <router-link to="/contact" v-html="$t('top.buttons.contact')" />

                    <!-- <a
                        v-html="$t('top.buttons.contact')"
                        @click="scrollTo($refs.contact.$el)"
                    >
                    </a> -->

                    <!-- <router-link to="/contact">
                        Contact Us
                    </router-link> -->
                </div>

                <img
                    src="/img/hero/mascot.png"
                    alt=""
                    class="intro__image"
                >
            </div>
        </div>

        <why-us @scrollTo="scrollTo($refs.features.$el)" />

        <our-work ref="ourwork" />

        <div class="features">
            <h2>{{ $t('features.heading') }}</h2>

            <div class="features__content">
                <h3 class="features__content--first-text">
                    {{ $t('features.text.first') }}
                </h3>

                <img src="/img/features-home.png" alt="" class="features__content--image">

                <p class="features__content--second-text">
                    {{ $t('features.text.second') }}
                </p>

                <router-link to="/features" class="view-all features__content--button">
                    {{ $t('features.goToButton') }}
                </router-link>
            </div>
        </div>

        <!-- <button @click="setLocaleTest('bg')">
            SKIFT TIL BG
        </button>

        <br>
        <br>

        <button @click="setLocaleTest('en')">
            SKIFT TIL EN
        </button>  -->

        <email-us id="contact" ref="contact" />
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import OurWork from '@/components/OurWork'
import EmailUs from '@/components/EmailUs'
import WhyUs from '@/components/WhyUs'

export default {
    name: 'Home',
    metaInfo() {
        return {
            title: "Webby Apps - Creating web applications that fits your needs",
            meta: [
                {
                    name: 'description',
                    content: `
                        At Webby Apps we make all kind of websites.  All websites are
                        customized for your needs. Almost everything's possible. Visit the site to get more information.
                    `
                },
                {
                    property: 'og:title',
                    content: "Webby Apps - Creating web applications that fits your needs."
                },
                {
                    property: 'og:site_name',
                    content: 'Webby Apps'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    name: 'theme-color',
                    content: '#616062'
                },
                {
                    name: 'robots',
                    content: 'index,follow'
                },
            ]
        }
    },
    components: {
        OurWork,
        EmailUs,
        WhyUs,
    },
    computed: {
        ...mapState(['device']),
        currentLocale() {
            return this.$i18n.locale
        },
    },
    methods: {
        ...mapMutations(['setLocale']),
        setLocaleTest(locale) {
            this.setLocale()

            this.$i18n.locale = locale
        },
        scrollTo(ref) {
            console.log('ref', ref)
            ref.scrollIntoView()

            if (this.device.includes('mobile')) {
                window.scrollBy(0, -60)
            } else if (this.device.includes('desktop-lower')) {
                window.scrollBy(0, 0)
            } else if (this.device.includes('desktop-higher')) {
                window.scrollBy(0, 0)
            } else if (this.device.includes('desktop')) {
                window.scrollBy(0, -70)
            }
        },
        refScrollUrl(ref) {
            ref.scrollIntoView()

            window.scrollBy(0, -100)
        },
    },
    mounted() {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 0)

        setTimeout(() => {
            if (window.location.href && window.location.href.includes('#features')) this.refScrollUrl(this.$refs.features.$el)
        }, 100)
    },
}
</script>

<style lang="scss">
.home {
    .intro {
        height: 350px;
        margin: 0 -18px;
        padding: 48px 18px;
        color: white;
        background-color: #616062;
        background-size: contain;

        h1 {
            padding-top: 12px;
            font-size: 20px;
            font-weight: 300;
            text-align: left;

            @media (min-width: 1024px) {
                margin-bottom: 36px;
                font-size: 40px;
            }
        }

        @media (min-width: 768px) {
            position: relative;
            padding: 60px 24px 48px;
        }

        @media (min-width: 1024px) {
            padding: 78px 48px 48px;
            background-repeat: round;
        }

        @media (min-width: 1300px) {
            padding: 132px 66px 66px;
            background-size: cover;
        }

        &__text {
            position: relative;

            @media (min-width: 768px) {
                width: 50%;
                height: auto;
                margin-bottom: 36px;
            }

            h2 {
                margin-bottom: 12px;
                font-size: 14px;
                font-weight: 500;
                text-align: left;

                @media (min-width: 1300px) {
                    font-size: 18px;
                }
            }
        }

        &__content {
            display: flex;
        }

        &__image {
            width: 170px;
            margin-right: -18px;

            @media (min-width: 375px) {
                margin: auto 0 auto auto;
            }

            @media (min-width: 768px) {
                position: absolute;
                top: 50%;
                right: 24px;
                width: 300px;
                margin-right: 0;
                transform: translateY(-50%);
                pointer-events: none;
            }

            @media (min-width: 1024px) {
                right: 48px;
                width: 350px;
            }

            @media (min-width: 1300px) {
                top: 55%;
                right: 66px;
                width: 400px;
            }
        }
    }

    .button-group {
        display: flex;
        position: relative;
        margin-top: 12px;
        flex-direction: column;

        @media (min-width: 375px) {
            margin-top: 24px;
        }

        @media (min-width: 768px) {
            justify-content: flex-start;
        }

        a {
            width: 130px;
            margin: 12px 0;
            padding: 8px 0;
            border-radius: 10px;
            color: #21647a;
            background-color: #d5e8f2;
            text-align: center;

            @media (min-width: 768px) {
                &:first-child {
                    margin-right: 36px;
                }
            }
        }
    }

    .features {
        position: relative;
        z-index: 4;
        margin: 0 -18px;
        padding: 48px 18px;
        color: white;
        background-color: #979797;
        box-shadow: #00000061 0 19px 38px;
        text-align: center;

        @media (min-width: 768px) {
            padding: 48px 24px;
        }

        @media (min-width: 1024px) {
            padding: 48px;
        }

        @media (min-width: 1300px) {
            padding: 66px;
        }

        &__content {
            @media (min-width: 768px) {
                display: grid;
                font-size: 18px;
                text-align: justify;
                grid-template-areas:
                    "image firstText"
                    "image secondText"
                    "image button";
                grid-template-columns: 350px 1fr;
                column-gap: 54px;
            }

            @media (min-width: 1024px) {
                grid-template-columns: 550px 1fr;
            }

            @media (min-width: 1300px) {
                grid-template-columns: 650px 1fr;
            }

            &--image {
                grid-area: image;
            }

            &--first-text {
                grid-area: firstText;
            }

            &--second-text {
                grid-area: secondText;

                @media (min-width: 1300px) {
                    font-size: 19px;
                }
            }

            &--button {
                grid-area: button;

                @media (min-width: 768px) {
                    height: max-content;
                    margin: 36px auto 0 0 !important;
                }
            }
        }

        h3 {
            @media (min-width: 1300px) {
                font-size: 32px;
                text-align: left;
            }
        }

        img {
            width: 100%;

            @media (min-width: 768px) {
                margin: auto;
            }
        }
    }

    .view-all {
        display: block;
        width: max-content;
        margin: 36px auto 0;
        padding: 16px 24px;
        border-radius: 8px;
        color: #21647a;
        background-color: #d5e8f2;
        font-size: 20px;
        font-weight: 700;
        text-align: right;
    }
}
</style>
